@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --bg-hero-url: url('../public/images/welcome-bg.webp');
    --left-bg-url: url('../public/images/left-icon.webp');
    --right-bg-url: url('../public/images/right-icon.webp');
    --qr-url: url('../public/images/contact.webp');
}

html {
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
}

html::-webkit-scrollbar {
    /* WebKit */
    width: 0px;
}

.source-code-pro {
    font-family: 'Source Code Pro', sans-serif;
}

.dancing-script {
    font-family: 'Dancing Script', sans-serif;
}

.progress-container {
    width: 100%;
    height: 1px;
    background: #aeaeae;
}

.progress-bar {
    height: 1px;
    background: #1cc3b2;
    width: 0%;
}

.brand-text-shadow {
    text-shadow: 1px 1px #393E46;
}

.hero-textp-shadow {
    text-shadow: 3px 3px #0e2737;
}

.bg-hero {
    background: rgba(0, 0, 0, .7);
    background-blend-mode: darken;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* Zoom-n-rotate Container */
.img-hover-zoom img {
    transition: transform .5s ease-in-out;
}

/* The Transformation */
.img-hover-zoom:hover img {
    transform: scale(1.2);
}

.img-project-zoom {
    position: relative;
    transition: transform 0.3s ease-in-out;
}

.img-project-zoom:hover {
    transform: scale(1.1);
}

.img-project-zoom img {
    display: block;
    transition: opacity 0.3s ease;
}

.img-project-zoom .text-preview {
    background: rgba(0, 0, 0, .1);
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
}

.img-project-zoom:hover img {
    opacity: 0.5;
}

.img-project-zoom:hover .text-preview {
    opacity: 1;
}

.content-box {
    box-shadow: 0 2px 20px 5px rgba(0, 0, 0, .1);
}

.content-box:hover {
    box-shadow: 0 2px 20px 5px rgba(28, 195, 178, .3);
}

.skill-box {
    box-shadow: 0 2px 20px 5px rgba(0, 0, 0, .1);
}

.skill-box:hover {
    /* transform: scale(1.2); */
    box-shadow: 0 2px 20px 5px rgba(28, 195, 178, .3);
}

.left-bg {
    background: var(--left-bg-url);
    background-position: right;
    background-repeat: no-repeat;
    background-size: 40px;
}

.right-bg {
    background: var(--right-bg-url);
    background-position: left;
    background-repeat: no-repeat;
    background-size: 40px;
}

.guest-box {
    transition: all .5s ease-in-out;
}

.guest-box:hover {
    transform: scale(1.02);
}

.guest-link {
    transition: all 1s ease-in-out;
}

.guest-link:hover {
    transform: translateX(20px);
}

.qr {
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
}

@media screen and (max-width: 600px) {
    .qr {
        background: #0e2737;
    }
}

.custom-shape-divider-bottom-1682761876 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1682761876 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 70px;
}

.custom-shape-divider-bottom-1682761876 .shape-fill {
    fill: #ffffff;
}

.custom-shape-divider-bottom-1682762669 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1682762669 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 70px;
}

.custom-shape-divider-bottom-1682762669 .shape-fill {
    fill: #010409;
}

.elipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.splash-container {
    display: flex;
    align-items: center;
    justify-content: center;
    animation: lift 0.8s ease-in-out;
    animation-delay: 1.6s;
    animation-fill-mode: forwards;
}

.splash {
    text-align: center;
    animation: wave 0.4s, jump 1s;
    position: relative;
    padding: 4px;
    transform: translate3d(0, 16%, 0);
    opacity: 0;
    z-index: 3;
    animation-fill-mode: forwards;
}

span:nth-of-type(1) {
    /* left: 0rem; */
    animation: wave 0.4s, jump 1.1s ease-in-out alternate 0.05s;
}

span:nth-of-type(2) {
    /* left: 0.8rem; */
    animation: wave 0.4s, jump 1.1s ease-in-out alternate 0.1s;
}

span:nth-of-type(3) {
    /* left: 1.6rem; */
    animation: wave 0.4s, jump 1.1s ease-in-out alternate 0.15s;
}

span:nth-of-type(4) {
    /* left: 2.4rem; */
    animation: wave 0.4s, jump 1.1s ease-in-out alternate 0.2s;
}

span:nth-of-type(5) {
    /* left: 3.2rem; */
    animation: wave 0.4s, jump 1.1s ease-in-out alternate 0.25s;
}

span:nth-of-type(6) {
    /* left: 4rem; */
    animation: wave 0.4s, jump 1.1s ease-in-out alternate 0.3s;
}

span:nth-of-type(7) {
    /* left: 4.8rem; */
    animation: wave 0.4s, jump 1.1s ease-in-out alternate 0.35s;
}

span:nth-of-type(8) {
    /* left: 5.6rem; */
    animation: wave 0.4s, jump 1.1s ease-in-out alternate 0.4s;
}

span:nth-of-type(9) {
    /* left: 6.4rem; */
    animation: wave 0.4s, jump 1.1s ease-in-out alternate 0.45s;
}

span:nth-of-type(10) {
    /* left: 6.4rem; */
    animation: wave 0.4s, jump 1.1s ease-in-out alternate 0.5s;
}

span:nth-of-type(11) {
    /* left: 6.4rem; */
    animation: wave 0.4s, jump 1.1s ease-in-out alternate 0.55s;
}

@keyframes wave {
    0% {
        top: 0%;
    }

    100% {
        top: 100%;
    }
}

@keyframes jump {
    0% {
        transform: translate3d(0, 0, 0);
        opacity: 0;
    }

    90% {
        transform: translate3d(0, -16%, 0);
        opacity: 1;
    }

    100% {
        transform: translate3d(0, -32%, 0);
        opacity: 1;
    }
}

@keyframes lift {
    0% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        visibility: visible;
    }

    100% {
        transform: translate3d(0, -100%, 0);
        opacity: 1;
        visibility: hidden;
    }
}

@keyframes appear {
    0% {
        visibility: hidden;
    }

    100% {
        visibility: visible;
    }
}

.masuk {
    animation: fadeIn .5s ease-in both;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate3d(0, 60%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}